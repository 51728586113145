import { getActiveTabStorage, setActiveTabStorage } from "@/utils/storages"

const sidebar = {
  state: {
    collapse: false,  // 是否折叠
    active_tab: getActiveTabStorage(),  // 当前激活的标签item
    tabPanes: [ // tab标签
      { title: "首页", name: "dashboard", path: "/dashboard", meta: { icon: 'fa fa-dashboard' } }
    ],
    tagsIncludes: ["dashboard"], // 需要缓存的页面name
    tagsExcludes: [], // 不需要缓存的页面
    device: "pc"  // 判断设备pc或者mobile
  },
  mutations: {
    setCollapse(state, val) {
      let collapse = state.collapse
      if (val == "undefined" || val == null || val == "") {
        collapse = !state.collapse
      } else {
        collapse = val
      }
      state.collapse = collapse
    },
    setActiveTab(state, item) {
      state.active_tab = item
    },
    addTabPanes(state, item) {
      if (item && item.path) {
        let isExist = false
        for (var i in state.tabPanes) {
          if (state.tabPanes[i].path === item.path) {
            isExist = true
            break
          }
        }
        if (!isExist) {
          state.tabPanes.push(item)
        } else {
          state.tabPanes[i].query = item.query || {}
        }
      } else {
        console.log("数据错误!")
      }
    },
    deleteTabPanes(state, path) {
      for (var i in state.tabPanes) {
        //删除当前，并设置前一个对象为当前的
        if (state.tabPanes[i].path === path) {
          if (state.active_tab && state.active_tab.path === path) {
            state.active_tab = state.tabPanes[i - 1]
            setActiveTabStorage(state.active_tab)
          }
          state.tabPanes.splice(i, 1)
        }
      }
    },
    deleteTabPanesArray(state, arr) {
      state.tabPanes.splice(...arr)
    },
    toggleDevice(state, device) {
      state.device = device
    },
    addTagsInclude(state, item) {
      if (item && item.name) {
        let isExist = false
        for (var i in state.tagsIncludes) {
          if (state.tagsIncludes[i] === item.name) {
            isExist = true
            break
          }
        }
        if (!isExist) {
          state.tagsIncludes.push(item.name)
        } 
      } else {
        console.log("数据错误!")
      }
    },
    deleteTagsInclude(state, names) {
      //删除指定的的tags
      for (let i in names) {
        let name = names[i]
        let index = state.tagsIncludes.indexOf(name)
        if (index !== -1) {
          state.tagsIncludes.splice(index, 1)
        }
      }
    },
    addTagsExclude(state, item) {
      if (item && item.name) {
        let isExist = false
        for (var i in state.tagsExcludes) {
          if (state.tagsExcludes[i] === item.name) {
            isExist = true
            break
          }
        }
        if (!isExist) {
          state.tagsExcludes.push(item.name)
        } 
      } else {
        console.log("数据错误!")
      }
    },
    deleteTagsExclude(state, name) {
      for (var i in state.tagsExcludes) {
        //删除当前的tag
        if (state.tagsExcludes[i] === name) {
          state.tagsExcludes.splice(i, 1)
        }
      }
    }
  },
  actions: {
    setCollapse({ commit }, val) {
      commit("setCollapse", val)
    },
    setActiveTab({ commit }, item) {
      return new Promise(resolve => {
        if (window.$common) {
          window.$common.pageRefresh = true
        }
        commit("setActiveTab", item)
        setActiveTabStorage(item)
        resolve()
      })
    },
    addTabPanes({ commit }, item) {
      return new Promise(resolve => {
        commit("addTabPanes", item)
        resolve()
      })
    },
    deleteTabPanes({ commit }, path) {
      return new Promise(resolve => {
        commit("deleteTabPanes", path)
        resolve()
      })
    },
    deleteTabPanesArray({ commit }, arr) {
      return new Promise(resolve => {
        commit("deleteTabPanesArray", arr)
        resolve()
      })
    },
    toggleDevice({ commit }, device) {
      commit("toggleDevice", device)
    },
    addTagsInclude({ commit }, item) {
      return new Promise(resolve => {
        commit("addTagsInclude", item)
        resolve()
      })
    },
    deleteTagsInclude({ commit }, name) {
      return new Promise(resolve => {
        commit("deleteTagsInclude", name)
        resolve()
      })
    },
    addTagsExclude({ commit }, item) {
      return new Promise(resolve => {
        commit("addTagsExclude", item)
        resolve()
      })
    },
    deleteTagsExclude({ commit }, name) {
      return new Promise(resolve => {
        commit("deleteTagsExclude", name)
        resolve()
      })
    }
  }
}
export default sidebar

