import Layout from '@/views/admin/layout/Layout'

/**
* path: ''                       请使用全地址，例如：/system/user 或者 http:www.xxx.com 便于路由定位
* hidden: true                   设为true，则不会显示到菜单栏
* alwaysShow: true               设为true，则永远显示一级栏目
* redirect: noredirect           默认重定向，当没有找到地址时重定向的去向
* name:'router-name'             <keep-alive>需要使用 (must set!!!)
* meta : {
    title: 'title'               显示的名称
    icon: 'svg-name'             显示的图标
    roles: ['']                  权限
    isSkip: true                 如果菜单可以有下级且自己本身也需要打开页面，则配置isSkip
  }
  注意：如果有第三级及多级菜单，则path配全地址，“/path1/path2/...”
**/

const routerMap = [
  {
    path: '/',
    component: Layout,
    hidden: true,
    redirect: '/dashboard',
    meta: { title: '首页', icon: 'fa fa-gear' }, // , roles: ['System']
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/admin/dashboard/index'),
        meta: { title: '首页', icon: 'fa fa-dashboard' }
      }
    ]
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: Layout,
    meta: { title: '库存管理', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock'] },
    redirect: '/stock/list',
    children: [
      {
        path: '/stock/list',
        name: 'pageStockList',
        component: () => import('@/views/admin/stock/list'),
        meta: { isPageHead: true, parent: '库存管理', title: '仓库库存', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.WarehouseStock'] }
      },
      {
        path: '/stock/assetUserStock',
        name: 'pageAssetUserStock',
        component: () => import('@/views/admin/stock/assetUserStock'),
        meta: { isPageHead: true, parent: '库存管理', title: '使用者库存', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.AssetUserStock'] }
      },
      {
        path: '/stock/tempStock',
        name: 'pageTempStock',
        component: () => import('@/views/admin/stock/tempStock'),
        meta: { isPageHead: true, parent: '库存管理', title: '临时库存', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.TemporaryStock'] }
      },
      {
        path: '/stock/scrapStock',
        name: 'pageScrapStock',
        component: () => import('@/views/admin/stock/scrapStock'),
        meta: { isPageHead: true, parent: '库存管理', title: '报废库存', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.DamagedStock'] }
      },
      {
        path: '/stock/inStockBill',
        name: 'pageInStockBill',
        hidden: true,
        component: () => import('@/views/admin/stock/inStockBill'),
        meta: { isPageHead: true, parent: '库存管理', title: '入库单', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.Inbound'] }
      },
      {
        path: '/stock/outStockBill',
        name: 'pageOutStockBill',
        hidden: true,
        component: () => import('@/views/admin/stock/outStockBill'),
        meta: { isPageHead: true, parent: '库存管理', title: '出库单', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.Outbound'] }
      },
      {
        path: '/stock/transferStockBill',
        name: 'pageTransferStockBill',
        hidden: true,
        component: () => import('@/views/admin/stock/transferStockBill'),
        meta: { isPageHead: true, parent: '库存管理', title: '调库单', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.Transfer'] }
      },
      {
        path: '/stock/exchangeBill',
        name: 'pageExchangeStockBill',
        hidden: true,
        component: () => import('@/views/admin/stock/exchangeBill'),
        meta: { isPageHead: true, parent: '库存管理', title: '更换单', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.Exchange'] }
      },
      {
        path: '/stock/damageBill',
        name: 'pageDamageStockBill',
        hidden: true,
        component: () => import('@/views/admin/stock/damageBill'),
        meta: { isPageHead: true, parent: '库存管理', title: '报废单', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.Damage'] }
      },
      {
        path: '/stock/logs',
        name: 'pageStockLogs',
        component: () => import('@/views/admin/stock/logs'),
        meta: { isPageHead: true, parent: '库存管理', title: '库存日志', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.ChangeLogs'] }
      },
      {
        path: '/stock/takeStock',
        name: 'pageTakeStock',
        component: () => import('@/views/admin/stock/takeStock'),
        meta: { isPageHead: true, parent: '库存管理', title: '库存盘点', icon: 'fa fa-inbox', roles: ['AssetManagement.Stock.AssetStock'] }
      }
    ]
  },
  {
    path: '/infomations',
    name: 'infomations',
    component: Layout,
    meta: { title: '信息管理', icon: 'fa fa-book', roles: ['BaseDataManagement'] },
    redirect: '/assets/list',
    children: [
      {
        path: '/assets/type',
        name: 'pageAssetsType',
        component: () => import('@/views/admin/assets/type'),
        meta: { isPageHead: true, parent: '分类管理', title: '资产分类', icon: 'fa fa-book', roles: ['BaseDataManagement.BaseData.AssetCategoryManage'] }
      },
      {
        path: '/assets/list',
        name: 'pageAssetList',
        component: () => import('@/views/admin/assets/list'),
        meta: { isPageHead: true, parent: '信息管理', title: '资产信息', icon: 'fa fa-book', roles: ['BaseDataManagement.BaseData.AssetManage'] }
      },
      {
        path: '/company/list',
        name: 'pageCompanyList',
        component: () => import('@/views/admin/company/list'),
        meta: { isPageHead: true, parent: '信息管理', title: '事业部管理', icon: 'fa fa-book', roles: ['BaseDataManagement.BaseData.CompanyManage'] }
      },
      {
        path: '/warehouse/list',
        name: 'pageWarehouseList',
        component: () => import('@/views/admin/warehouse/list'),
        meta: { isPageHead: true, parent: '信息管理', title: '仓库管理', icon: 'fa fa-book', roles: ['BaseDataManagement.BaseData.WarehouseManage'] }
      },
      {
        path: '/assetsUser/type',
        name: 'pageAssetsUserType',
        component: () => import('@/views/admin/assetsUser/type'),
        meta: { isPageHead: true, parent: '分类管理', title: '使用者分类', icon: 'fa fa-book', roles: ['BaseDataManagement.BaseData.AssetUserCategoryManage'] }
      },
      {
        path: '/assetsUser/list',
        name: 'pageAssetUserList',
        component: () => import('@/views/admin/assetsUser/list'),
        meta: { isPageHead: true, parent: '信息管理', title: '使用者管理', icon: 'fa fa-book', roles: ['BaseDataManagement.BaseData.AssetUserManage'] }
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: Layout,
    meta: { title: '系统管理', icon: 'fa fa-gear', roles: ['SystemManagement'] },
    redirect: '/system/sysConfig',
    children: [
      {
        path: '/system/sysConfig',
        name: 'sysConfig',
        component: () => import('@/views/admin/system/sysConfig'),
        meta: { isPageHead: true, parent: '系统管理', title: '系统配置', icon: 'fa fa-users', roles: ['SystemManagement.SystemSettings'] }
      },
      {
        path: '/system/loginLogs',
        name: 'loginLogs',
        component: () => import('@/views/admin/system/loginLogs'),
        meta: { isPageHead: true, parent: '系统管理', title: '登录日志', icon: 'fa fa-file-text-o', roles: ['SystemManagement.Log.SecurityLog'] }
      },
      {
        path: '/system/operationLogs',
        name: 'operationLogs',
        component: () => import('@/views/admin/system/operationLogs'),
        meta: { isPageHead: true, parent: '系统管理', title: '操作日志', icon: 'fa fa-book', roles: ['SystemManagement.Log.AuditLog'] }
      }
    ]
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: Layout,
    meta: { title: '权限管理', icon: 'fa fa-user', roles: ['AbpIdentity'] },
    redirect: '/system/role',
    children: [
      {
        path: '/system/role',
        name: 'role',
        component: () => import('@/views/admin/system/role'),
        meta: { isPageHead: true, parent: '权限管理', title: '角色管理', icon: 'fa fa-users', roles: ['AbpIdentity.Roles'] }
      },
      {
        path: '/system/user',
        name: 'user',
        component: () => import('@/views/admin/system/user'),
        meta: { isPageHead: false, parent: '权限管理', title: '用户管理', icon: 'fa fa-user', roles: ['AbpIdentity.Users'] }
      }
    ]
  },
  {
    path: '/notice',
    name: 'notice',
    component: Layout,
    hidden: true,
    meta: { title: '文章管理', icon: 'fa fa-gear', roles: ['System'] },
    redirect: '/notice/list',
    children: [
      {
        path: '/notice/list',
        name: 'noticeList',
        component: () => import('@/views/admin/notice/list'),
        meta: { isPageHead: true, parent: '文章管理', title: '新闻中心', icon: 'fa fa-book', roles: ['System'] }
      }
    ]
  },
  {
    path: '/data',
    name: 'data',
    component: Layout,
    hidden: true,
    meta: { title: '数据统计', icon: 'fa fa-user', roles: ['System'] },
    redirect: '/data/basic',
    children: [
      {
        path: '/data/basic',
        name: 'basic',
        component: () => import('@/views/admin/data/basic'),
        meta: { title: '基础数据', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/keeping',
        name: 'keeping',
        component: () => import('@/views/admin/data/keeping'),
        meta: { title: '留存率', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/lossNode',
        name: 'lossNode',
        component: () => import('@/views/admin/data/lossNode'),
        meta: { title: '流失节点统计', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/online',
        name: 'online',
        component: () => import('@/views/admin/data/online'),
        meta: { title: '在线人数', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/customs',
        name: 'customs',
        component: () => import('@/views/admin/data/customs'),
        meta: { title: '关卡统计', icon: 'fa fa-user', roles: ['System'] }
      }
    ]
  },
  {
    path: '/material',
    name: 'material',
    component: Layout,
    alwaysShow: true,
    hidden: true,
    meta: { title: '游戏数据', icon: 'fa fa-user', roles: ['System'] },
    redirect: '/material/weapon',
    children: [
      {
        path: '/material/weapon',
        name: 'weapon',
        component: () => import('@/views/admin/material/weapon'),
        meta: { title: '武器状态', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/material/playingMain',
        name: 'playingMain',
        component: () => import('@/views/admin/material/playingMain'),
        meta: { title: '主线副本', icon: 'fa fa-user', roles: ['System'] }
      }
    ]
  }
]

export default routerMap
