<style lang="scss">
    .c-header {
      height: 100%;
      .c-header-left {
        float: left;
      }
      .c-header-logo {
        display: inline-block;
        margin: 10px 10px 0 10px;
        color: #fff;
        vertical-align: middle;
      }
      .c-header-icon {
        display: inline-block;
        font-size: 36px;
        vertical-align: middle;
      }
      .c-header-title {
        display: inline-block;
        margin-left: 5px;
        font-size: 22px;
        vertical-align: middle
      }
      .c-header-collage {
        display: inline-block;
        margin: 12px 15px 0 0;
        padding: 6px 10px;
        vertical-align: middle;
        color: #fff;
        i {
          font-size: 22px;
        }
      }
      .c-header-collage:hover {
        background: $c-bgHoverColor;
      }
      .c-header-user {
        margin: 10px 10px 0 0;
        float: right;
        .el-button {
          padding: 10px;
        }
        .el-button--primary {
          background: $c-bgColor;
          border-color: $c-bgColor;
        }
        .el-button--primary:hover {
          background: $c-bgHoverColor;
        }
      }
      .c-hearder-btn {
        float: right;
        margin: 15px 10px 0 0;
        padding: 4px;
        font-size: 16px;
        background: rgba(255, 255, 255, 0.2);
      }
      .c-skin-color {
        display: block;
        margin: 10px 10%;
        width: 80%;
        height: 40px;
        line-height: 40px;
        border-radius: 6px;
        color: #fff;
        text-align: center;
      }
      .c-skin-drawer {
        min-width: 200px;
      }
    }
</style>

<template>
    <div class="c-header">
        <div class="c-header-left">
        <a href="/" class="c-header-logo">
            <i class="c-header-icon el-icon-coin"></i>
            <span class="c-header-title" v-if="$store.getters.device === 'pc'">{{ getSiteName }}</span>
        </a>
        <a href="javascript:void(0)" class="c-header-collage" @click="setCollapse">
            <i class="el-icon-s-unfold" v-if="$store.getters.collapse"></i>
            <i class="el-icon-s-fold" v-else></i>
        </a>
        </div>
        <header-bar v-if="false"></header-bar>
        <el-dropdown class="c-header-user" trigger="click">
          <el-button type="primary">
              <i class="el-icon-s-custom"></i>
              {{ this.$store.getters.name }}
              <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="_updatePassword()"><i class="el-icon-lock"></i> 修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="loginOut"><i class="el-icon-switch-button"></i> 退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" class="c-hearder-btn" title="切换皮肤" @click="showSkinPanel(true)"><i class="el-icon-s-flag"></i></el-button>
        <el-button type="primary" class="c-hearder-btn" title="全屏显示" @click="fullScreen()"><i class="fa fa-arrows-alt"></i></el-button>
        <!--皮肤-->
        <el-drawer custom-class="c-skin-drawer" title="切换皮肤" :with-header="false" :visible.sync="showSkinTool">
          <el-row :gutter="10">
            <el-col :span="12" v-for="(name, index) in skinThemeNames" :key="name">
              <a href="javascript:void(0)" class="c-skin-color" :style="'background: #' + name" @click="getSkinName(index)">{{ '#' + name }}</a>
            </el-col>
          </el-row>
        </el-drawer>
        <!--修改密码-->
        <password :ref="refPassword"></password>
    </div>
</template>

<script>
import HeaderBar from '../components/HeaderBar'
import { validPassword } from '@/utils/validate'
import skinTheme from '@/mixins/skinTheme'
import password from '../components/Password'
export default {
    name: "headers",
    components: {
      HeaderBar,
      password
    },
    mixins: [skinTheme],
    data() {
      return {
        showSkinTool: false,
        refPassword: "refHeadersToPassword"
      }
    },
    created() {
      
    },
    computed: {
      getSiteName() {
        return this.$store && this.$store.getters && this.$store.getters.systemConfig 
                            && this.$store.getters.systemConfig.systemName
      }
    },
    mounted() {
      // 获取皮肤
      this.getSkinByState()
    },
    methods: {
      setCollapse() {
        this.$store.dispatch('setCollapse')
      },
      _updatePassword() {
        this.$refs[this.refPassword].showDialog = true
      },
      loginOut() {
        this.confirm("确定要退出登录？").then(() => {
          this.$store.dispatch('loginOut').then(() => {
            location.reload()
            // this.$router.push({ path: '/login' })
          })
        })
      },
      // 切换皮肤
      showSkinPanel(state) {
        this.showSkinTool = state || false
      },
      // 获取皮肤颜色并切换
      getSkinName(index) {
        this.changeSkin(index, () => {
          this.showSkinPanel()
        })
      },
      // 执行全屏操作
      fullScreen() {
        if (this.fullScreenEnble()) {
          let element = document.documentElement;
          if (this.fullScreenElement()) {
            if (document.exitFullscreen) {
              document.exitFullscreen()
            } else if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen()
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen()
            } else if (document.msExitFullscreen) {
              document.msExitFullscreen()
            }
          } else {
            if (element.requestFullscreen) {
              element.requestFullscreen()
            } else if (element.webkitRequestFullScreen) {
              element.webkitRequestFullScreen()
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen()
            } else if (element.msRequestFullscreen) {
              // IE11
              element.msRequestFullscreen()
            }
          }
        }
      },
      // 返回可以处于全屏的元素
      fullScreenElement() {
        // 要在用户授权全屏后才能获取全屏的元素，否则 fullscreenEle为null
        let fullscreenEle = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement
        return fullscreenEle
      },
      // 是否可以请求全屏的状态
      fullScreenEnble() {
        // 要在用户授权全屏后才能准确获取当前的状态
        let isFullscreen = document.fullscreenEnabled || window.fullScreen || document.webkitIsFullScreen || document.msFullscreenEnabled
        return isFullscreen
      }
    }
}
</script>