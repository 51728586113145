import { EnumInit } from "@/api/all"

const webSite = {
  state: {
    enumConfigs: {}
  },
  mutations: {
    setEnumConfigs(state, enums) {
      state.enumConfigs = enums
    }
  },
  actions: {
    setEnumConfigs({ commit }) {
      return new Promise((resolve, reject) => {
        EnumInit().then(res => {
          commit('setEnumConfigs', res)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
export default webSite


