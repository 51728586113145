import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import permission from './modules/permission'
import sidebar from './modules/sidebar'
import common from './modules/common'
import webSite from './modules/webSite'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    permission,
    sidebar,
    common,
    webSite
  },
  getters
})

export default store

