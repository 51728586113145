import store from '@/store'

const { body } = document
const width = 768

export default {
  watch: {
    "$store.getters.device"() {
      this.getIsPc()
    }
  },
  beforeMount() {
    this.resizeHandler()
    this.getIsPc()
  },
  mounted() {
    const isMobile = this.isMobile()
    if (isMobile) {
      store.dispatch('toggleDevice', 'mobile')
      store.dispatch('setCollapse', isMobile)
    }
    // 监听浏览器改变
    window.addEventListener('resize', this.resizeHandler)
  },
  methods: {
    isMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width < width
    },
    resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.isMobile()
        store.dispatch('toggleDevice', isMobile ? 'mobile' : 'pc')
      }
    },
    /* 判断设备是否为pc或者mobile */
    getIsPc() {
      if (this.$store.getters.device === "mobile") {
          this.$root.isPc = false
      } else {
          this.$root.isPc = true
      }
    }
  }
}


