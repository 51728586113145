import request from '@/utils/request'

// 所有枚举
export function EnumInit() {
    return request({
        url: '/api/app/enum',
        method: 'get'
    })
}
// 所有分类，用于分类页面的编辑使用
export function CategoryAll(data) {
    return request({
        url: '/api/services/app/Category/GetAll',
        method: 'get',
        params: data
    })
}
// 所有的有效分类，主要用于各个地方的选择使用
export function CategoryActiveAll(data) {
    return request({
        url: '/api/services/app/Category/GetActiveItems',
        method: 'get',
        params: data
    })
}



