<style lang="scss">
  .c-body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: #f5f7fa;
    .el-container {
      height: 100%;
    }
    .c-container {
      height: calc(100% - 60px);
    }
    .el-header {
      padding: 0;
      text-align: left;
      background: $c-bgColor;
      /*box-shadow:0px 1px 10px -3px #000;*/
    }
    .el-aside {
      height: 100%;
      text-align: left;
      box-sizing: content-box;
      border-right: 1px solid #ddd;
      background-color: #fff;
    }

    .el-main {
      position: relative;
      padding: 0;
    }

    .c-main {
      width: 100%;
      height: calc(100% - 40px);
      background-color: #f5f7fa;
      margin-top: 40px;
      .c-pageheader {
        padding: 20px;
        background-color: #ffffff;
        .c-page-title {
          float: left;
          font-size: 18px;
          height: 26px;
          line-height: 26px;
        }
        .c-page-breadcrumb {
          float: right;
          font-weight: normal;
          color: #888;
          height: 26px;
          line-height: 26px;
          a,.is-link,.el-breadcrumb__inner  {
            color:#888;
            font-weight: normal;
          }
        }
        .c-page-desc {
          margin-top: 10px;
          color: #888;
        }
      }
      .c-content {
        min-height: 650px;
        margin: 15px;
        padding: 15px 15px 60px;
        background-color: #fff;
        position: relative;
      }
      .el-scrollbar {
        height: 100%;
        max-height: 100%;
        .el-scrollbar__wrap {
          overflow-x: hidden;
          .el-scrollbar__view {
            min-width: 288px;
            /* margin: 15px;
            padding: 15px;
            height: calc(100% - 60px); 
            background-color: #ffffff;*/
            .c-table-expand {
              label {
                min-width: 100px;
                line-height: 2;
                font-size: 14px;
                color: #99a9bf;
              }
              .el-form-item {
                margin-right: 0;
                margin-bottom: 0;
                min-width: 48%;
                .el-form-item__content {
                  width: calc(100% - 100px);
                  min-width: 100px;
                  word-break: break-all;
                  font-size: 14px;
                  line-height: 2;
                }
              }
            }
            .el-form--inline {
              .c-inline-item {
                width: calc(100% - 24px);
                .el-form-item__content {
                  width: calc(100% - 100px);
                  min-width: 220px;
                }
              }
            }
            .el-table__expanded-cell[class*=cell] {
              padding: 10px 10px 10px 50px;
            }
            .el-dialog {
              min-width: 360px;
            }
            .el-dialog__title {
              font-size: 16px;
            }
            .c-datetime {
              width: 100%;
              max-width: 192px;
            }
            .c-datetime-range {
              width: 100%;
              max-width: 340px;
            }
            .pagination-container {
              text-align: right;
              margin-top: 10px;
            }
            .el-pagination {
              white-space: normal;
            }
            .el-table--mini, .el-table--small, .el-table__expand-icon {
              font-size: 14px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 320px) {
      .c-main {
        .el-scrollbar {
          .el-scrollbar__wrap {
            .el-scrollbar__view {
              .el-dialog {
                min-width: 310px;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .c-main {
        .el-scrollbar {
          .el-scrollbar__wrap {
            .el-scrollbar__view {
              margin: 5px;
            }
          }
        }
        .c-content {
          margin: 15px 0;
          .el-form-item__label {
              text-align: left;
              width: auto !important;
          }
          .el-form-item__content {
              margin-left: 0 !important;
          }
          .el-button--medium {
              padding: 8px 15px;
          }
          .el-range-editor--medium.el-input__inner {
              height: 32px;
          }
          .el-input--medium .el-input__inner {
              height: 32px;
              line-height: 32px;
          }
          .el-pagination__total,
          .el-pagination__sizes,
          .el-pagination__jump,
          .btn-prev,
          .btn-next {
            display: none;
          }
        }
      }
    }
    .c-version.el-divider {
      background-color: #f5f5f5;
      position: absolute;
      bottom: 0;
      margin-top: 50px;
      width: calc(100% - 30px);
    }
  }
  .el-button + .el-button {
    margin-left: 5px !important;
  }
  .el-button-group > .el-button + .el-button {
    margin-left: 0 !important;
  }
</style>

<template>
  <div class="c-body">
    <el-container>
      <el-header>
        <top-header></top-header>
      </el-header>
      <el-container class="c-container">
        <side-bar></side-bar>
        <el-main>
          <tab-bar></tab-bar>
          <div class="c-main">
            <el-scrollbar ref="scrollbar">
              <div class="c-pageheader" v-if="pageHeader">
                <div class="c-page-title">{{ pageHeader.title }}</div>
                <el-breadcrumb class="c-page-breadcrumb" separator="/">
                  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                  <el-breadcrumb-item v-for="(ht, hi) in pageHeader.breadcrumb" :key="hi">{{ ht }}</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="clearb"></div>
                <div class="c-page-desc" v-if="pageHeader.desc">{{ pageHeader.desc }}</div>
              </div>
              <div class="c-content">
                <keep-alive :include="tagsIncludes">
                  <router-view @scrollbarUpdate="scrollbarUpdate" v-if="isRouterAlive" :key="key"></router-view>
                </keep-alive>
                <el-divider class="c-version">
                  <span class="cold7 tc f12">
                    当前版本：{{ configs.version }}
                    <el-divider direction="vertical"></el-divider>
                    更新时间：{{ configs.verTime }}
                  </span>
                </el-divider>
              </div>
            </el-scrollbar>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import TopHeader from './components/Headers'
import SideBar from './components/SideBar'
import TabBar from './components/TabBar'
import ResizeMixin from './mixin/resize'
export default {
  name: "layout",
  components: {
    TopHeader,
    SideBar,
    TabBar
  },
  mixins: [ResizeMixin],
  provide (){
     return {
       refreshRouterView: this.refreshRouterView
     }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    tagsIncludes() {
      // 缓存tab的name
      return this.$store.getters.tagsIncludes
      // return []
    },
    tagsExcludes() {
      return this.$store.getters.tagsExcludes
    },
    key() {
      //console.log(this.$store.getters.active_tab)
      //console.log(this.$store.getters.tabPanes)
      //console.log(window.$common && window.$common.pageRefresh)
      /*if (!window.$common.pageRefresh) {
        return this.$route.fullPath
      } else {
        var name = this.$route.name !== undefined ? this.$route.name + '-' + new Date() : this.$route + '-' + new Date()
        return name
      }*/
      //var name = this.$route.name !== undefined ? this.$route.name + '-' + new Date() : this.$route + '-' + new Date()
      //return name
      this.setSysPageHeader()
      return this.$route.fullPath
    },
    pageHeader() {
      let sysPages = this.$store.getters.sysPageHeader
      console.log(sysPages)
      if (sysPages && sysPages.breadcrumb && sysPages.breadcrumb.length > 0) {
        sysPages.breadcrumb = sysPages.breadcrumb.filter(x => {
          return !!x
        })
      }
      return sysPages
    },
    configs() {
      return window.configs
    }
  },
  mounted() {
    // 更新网页title
    document.title = this.appSiteName
    // 加载枚举
    this.$store.dispatch("setEnumConfigs")
  },
  methods: {
    refreshRouterView() {
       this.isRouterAlive = false
       // 刷新当前页，清除tags缓存，然重新添加缓存
       this.$store.dispatch("deleteTagsInclude", [this.$store.getters.active_tab.name])
       this.$nextTick(() => {
         this.isRouterAlive = true
         this.$store.dispatch("addTagsInclude", this.$store.getters.active_tab)
       })
    },
    scrollbarUpdate() {
      setTimeout(() => {
        console.log("执行滚动条更新!")
        this.$refs.scrollbar.update()
      }, 100)
    }
  }
}
</script>

