import request from '@/utils/request'

// 用户管理
export function create(data) {
  return request({
    url: '/api/app/user',
    method: 'post',
    data
  })
}

export function getDetail(data) {
  return request({
    url: '/api/app/user/' + data.id,
    method: 'get',
    params: data
  })
}

export function getAll(data) {
  return request({
    url: '/api/app/user',
    method: 'get',
    params: data
  })
}

export function update(data) {
  return request({
    url: '/api/app/user/' + data.id,
    method: 'put',
    data
  })
}

export function remove(data) {
  return request({
    url: '/api/app/user/' + data.id,
    method: 'delete',
    params: data
  })
}

// 登陆日志
export function getLoginLogs(data) {
  return request({
    url: '/api/app/security-log',
    method: 'get',
    params: data
  })
}

// 操作日志
export function getAuditLogs(data) {
  return request({
    url: '/api/app/audit-log',
    method: 'get',
    params: data
  })
}

export function changePassword(data) {
  return request({
    url: '/api/app/profile/change-password',
    method: 'post',
    data
  })
}

export function updateUserPassword(data) {
  return request({
    url: '/api/app/user/' + data.id + '/password',
    method: 'put',
    data
  })
}

export function getUserCompanies(data) {
  return request({
    url: '/api/app/user/' + data.id + '/companies',
    method: 'get'
  })
}

export function setUserCompanies(data) {
  return request({
    url: '/api/app/user/' + data.id + '/companies',
    method: 'post',
    data: data.companyIds
  })
}

export function getUserWarehouses(data) {
  return request({
    url: '/api/app/user/' + data.id + '/warehouses',
    method: 'get'
  })
}

export function setUserWarehouses(data) {
  return request({
    url: '/api/app/user/' + data.id + '/warehouses',
    method: 'post',
    data: data.warehouseIds
  })
}

export function getUserCompaniesAndWarehouses(data) {
  return request({
    url: '/api/app/user/' + data.id + '/companies-and-warehouses',
    method: 'get'
  })
}

export function setUserCompaniesAndWarehouses(data) {
  return request({
    url: '/api/app/user/' + data.id + '/companies-and-warehouses',
    method: 'post',
    data
  })
}

export function getUserRoles(data) {
  return request({
    url: '/api/app/user/' + data.id + '/roles',
    method: 'get'
  })
}

export function updateUserRoles(data) {
  return request({
    url: '/api/app/user/' + data.id + '/roles',
    method: 'put',
    data
  })
}

export function getAssignableRoles(data) {
  return request({
    url: '/api/app/user/assignable-roles',
    method: 'get',
    params: data
  })
}

export function findByUserName(data) {
  return request({
    url: '/api/app/user/find-by-username',
    method: 'post',
    data
  })
}

export function findByEmail(data) {
  return request({
    url: '/api/app/user/find-by-email',
    method: 'post',
    data
  })
}

export function setProfile(data) {
  return request({
    url: '/api/app/profile',
    method: 'put',
    data
  })
}
