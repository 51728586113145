import request from '@/utils/request'

// 系统配置
export function getSystemSettings() {
  return request({
    url: '/api/app/system-settings',
    method: 'get'
  })
}

export function updateSystemSettings(data) {
  return request({
    url: '/api/app/system-settings',
    method: 'put',
    data
  })
}

// 自定义配置
export function getCustomerSettings() {
  return request({
    url: '/api/services/app/CustomerSettings/Get',
    method: 'get'
  })
}

export function updateCustomerSettings(data) {
  return request({
    url: '/api/services/app/CustomerSettings/Update',
    method: 'put',
    data
  })
}

// blob
export function delBlob(url) {
  return request({
    url,
    method: 'delete'
  })
}

export function GetWechatTicket() {
  return request({
    url: '/api/Wechat/GetJsSdkUiPackage',
    method: 'get'
  })
}
